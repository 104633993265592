<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="ml-8">
        <div class="row">
          <p class="table-header">ข้อมูลรายวิชา</p>
          <p class="my-auto ml-auto">หลักสูตร</p>
          <select v-model="filtered.program" @change="courseFilter()" class="select-bar my-auto">
            <option value="ALL">ทั้งหมด</option>
            <option v-for="program in programs" :key="program.id" :value="program.code">{{ program.name }}</option>
          </select>
          
          <!-- Year filter-->
          <p class="ml-8 my-auto">ปีการศึกษา</p>
          <select v-model="filtered.year" @change="courseFilter()" class="year-bar">
            <option value="ALL">ทั้งหมด</option>
            <option v-for="year in years" :key="year.id" :value="year">{{year}}</option>
          </select>

          <router-link :to="'/course/add/'" class="ml-8">
            <button class="file-button">เพิ่มรายวิชา</button>
          </router-link>
        </div>

        <!-- Table loading -->
        <div v-if="table_loading" class="mt-8">
          <div class="loader m-auto"></div><br>
        </div>
        <div v-else-if="filtered.course.length > 0">
          <!-- Course list (Table) -->
          <table>
            <!-- Table header -->
            <thead>
              <tr>
                <th class="w-1/12">ลำดับ</th>
                <th class="w-1/12">รหัสวิชา</th>
                <th class="w-2/12">รายวิชา</th>
                <th class="w-4/12">หลักสูตร</th>
                <th class="w-4/12">ระยะเวลา</th>
                <th class="w-1/12"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <!-- Horizontal line -->
              <tr><td colspan="7"><hr></td></tr>
              <!-- Data -->
              <tr v-for="(course, index) in filtered.course" :key="course.id">
                <td>{{ index + 1 }}</td>
                <td>{{ course.code }}</td>
                <td>{{ course.type_name }}</td>
                <td class="whitespace-pre ...">{{ course.program_name }}</td>
                <td>{{ course.active_date_from }} - {{ course.active_date_to }}</td>
                <!-- Edit button -->
                <td>
                  <router-link :to="'/course/' + course.id + '/edit/'" class="mr-8">
                    <button class="view-button mx-auto" style="background-color: #FFC700;">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" stroke="white" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </button>
                  </router-link>
                </td>
                <!-- Delete button -->
                <td>
                  <button v-on:click="deleteCourse(course)" class="view-button mx-auto" style="background-color: #ff0000">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Not Found -->
        <div v-else class="text-not-found">ไม่พบข้อมูลที่สอดคล้องกับตัวกรองข้อมูลที่เลือก</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { courseList, changeProgramName } from "../mixins/other"

export default {
  name: "CourseManage",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      table_loading: true,
      courses: null,
      years: [],
      programs: [],
      filtered: {
        program: "ALL",
        year: "ALL",
        course: []
      },
      role: null
    }
  },
  async created() {
    document.title = "ข้อมูลรายวิชา | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    let course_list = courseList(await this.$store.dispatch("getCourse"), this.years)
    this.courses = course_list.courses.filter((course) => { return course.year >= 2564 })
    this.years = course_list.years.filter((year) => { return year >= 2564 })
    this.years = [... new Set(this.years)]
    this.programs = changeProgramName(await this.$store.dispatch("getProgram"))
    this.courseFilter()
    this.loading = false
    this.table_loading = false
  },
  methods: {
    courseFilter() {
      this.table_loading = true
      this.filtered.course = []
      this.courses.filter((course) => {
        if (this.filtered.year == "ALL" && this.filtered.program == "ALL") {
          this.filtered.course = this.courses
        } 
        else if (course.year == this.filtered.year && course.program_code == this.filtered.program ||
                this.filtered.year == "ALL" && course.program_code == this.filtered.program ||
                course.year == this.filtered.year && this.filtered.program == "ALL") {
          this.filtered.course.push(course)
        }
      })
      this.filtered.course.sort(function(c1, c2) { return c1.code - c2.code })
      this.filtered.course.sort(function(c1, c2) { return c2.year - c1.year })
      this.table_loading = false
    },
    async deleteCourse(course) {
      if (confirm("ต้องการลบรายวิชา " + course.type_name + " (" + course.program_code +  ") ปีการศึกษา " + course.year +" หรือไม่") == true) {
        const success = await this.$store.dispatch("deleteCourseById", course.id)
        if (success) {
          alert("ลบรายวิชาเสร็จสิ้น")
          location.reload()
        }
      }
    }
  },
}
</script>
